// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-admissions-jsx": () => import("./../../../src/pages/admissions.jsx" /* webpackChunkName: "component---src-pages-admissions-jsx" */),
  "component---src-pages-admissions-map-jsx": () => import("./../../../src/pages/admissions-map.jsx" /* webpackChunkName: "component---src-pages-admissions-map-jsx" */),
  "component---src-pages-case-map-jsx": () => import("./../../../src/pages/case-map.jsx" /* webpackChunkName: "component---src-pages-case-map-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-ltla-jsx": () => import("./../../../src/pages/ltla.jsx" /* webpackChunkName: "component---src-pages-ltla-jsx" */),
  "component---src-pages-patients-jsx": () => import("./../../../src/pages/patients.jsx" /* webpackChunkName: "component---src-pages-patients-jsx" */),
  "component---src-pages-patients-map-jsx": () => import("./../../../src/pages/patients-map.jsx" /* webpackChunkName: "component---src-pages-patients-map-jsx" */),
  "component---src-pages-utla-jsx": () => import("./../../../src/pages/utla.jsx" /* webpackChunkName: "component---src-pages-utla-jsx" */)
}

